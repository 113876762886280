import { serverTimestamp as firebaseServerTimestamp } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, connectAuthEmulator, getAuth, initializeAuth } from 'firebase/auth';

import { selectedSettings } from 'common/config/firebase-config';
import { debugCheck } from './debug-check';

let firebaseApp;

export function initializeFirebase({ shouldUseLocalhostAuth }: { shouldUseLocalhostAuth: boolean }) {
  debugCheck(!firebaseApp, 'firebase already initialized before firebaseInitialized()!');
  firebaseApp = initializeApp(selectedSettings.firebaseConfig);
  initializeAuth(firebaseApp, { persistence: browserLocalPersistence });

  if (shouldUseLocalhostAuth) connectAuthEmulator(getAuth(), 'http://localhost:9099');
}

export function getFirebaseApp() {
  debugCheck(!!firebaseApp, 'firebase not initialized before getFirebaseApp()!');

  return firebaseApp;
}

export function serverTimestamp() {
  return firebaseServerTimestamp() as unknown as number;
}
