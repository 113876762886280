import { createBrowserRouter } from 'react-router-dom';

import { exposeToGlobalConsole } from './react/expose-to-global-console';

export type Router = ReturnType<typeof createBrowserRouter>;

let router: Router;

export const setRouter = (passedRouter: Router) => (router = passedRouter);
export const getRouter = () => router;

exposeToGlobalConsole({ getRouter });
