const productionReleases = ['ci', 'dev', 'prod'];
export const isTrulyProd = 'prod' === process.env.FIREBASE_ENV;
export const isProd = productionReleases.includes(process.env.FIREBASE_ENV!);
export const productName = process.env.FIREBASE_ENV === 'prod' ? 'Pop' : 'Pop-' + process.env.FIREBASE_ENV;
export const protocolName = productName.toLowerCase();
export const debOrRpmProductName = productName.toLowerCase();
export const sentryEnabledProjects = ['pop', 'pop-dev'];

export const sentryClientKey =
  'https://8b8bfbe176418beab2ea2e76d8dd89fe@o264355.ingest.us.sentry.io/4508065543290880';

export const sentryServerKey =
  'https://a1e18e6f3ac9665465a98ed0732b9e13@o264355.ingest.us.sentry.io/4508066072166400';
