import autoBind from 'auto-bind';

const allLegacyStorageKeys = [
  'displayName',
  'enableMediaOnStart',
  'email',
  'isLocalFloof',
  'setFakePoeHostname',
  'setRegion',
  'setRegionDelayMs',
  'setIceDelayMs',
  'setProtocolUrl',
  'shouldUseCustomElectron',
  'shouldUseH264',
  'shouldEncoderGenerateFrames',
  'shouldSimulcastP2pScreenShare',
  'cpuThrottleMinPct',
  'cpuThrottleMaxPct',
  'shouldDropHostIceCandidates',
  'shouldDropReflexIceCandidates',
  'shouldDropRelayIceCandidates',
  'shouldUseLocalhostAuth',
  'serverSettings',
  'wasPreviousLaunchLoggedIn',
  'is3dDomain',
  'lastSelectedTab',
  'userPreferences',
  'didShowLinuxI3Alert',
  'pttWaveUrl',
  'lastPageId',
  'lastParams',
] as const;
type LegacyStorageKey = typeof allLegacyStorageKeys[number];

const allStorageKeys = [
  'device-preference-order',
  'should-use-localhost-auth',
  'should-use-local-floof',
  'should-mirror-local-iago-events',
  'should-block-local-iago-events',
] as const;
export type StorageKey = typeof allStorageKeys[number];

class Storage<T> {
  private data: any;
  constructor(private storageRoot: string) {
    autoBind(this);
    this.readData();
  }

  private readData() {
    try {
      const dataStr = localStorage.getItem(this.storageRoot);
      if (dataStr) {
        this.data = JSON.parse(dataStr);
      } else {
        this.data = {};
      }
    } catch (e) {
      this.data = {};
    }
    this.writeData();
  }

  private writeData() {
    localStorage.setItem(this.storageRoot, JSON.stringify(this.data));
  }

  public reset() {
    this.data = {};
    this.writeData();
  }

  public get(key: T) {
    const value = this.data[key];
    return value;
  }

  public set(key: T, value) {
    // TODO-LEGACY: Remove this line when Angular is gone
    this.readData();

    this.data[key] = value;
    this.writeData();
  }

  public delete(key: T) {
    delete this.data[key];
    this.writeData();
  }
}

export const storage = new Storage<StorageKey>('storage');
export const legacyStorage = new Storage<LegacyStorageKey>('data');
(window as any).storage = storage;
(window as any).legacyStorage = legacyStorage;
