import { assign } from 'lodash';

const globals = {};

export const exposeToGlobalConsole = (obj: Record<string, any>) => {
  assign(globals, obj);
};

export const concealFromGlobalConsole = (...keys: string[]) =>
  keys.forEach((key) => delete globals[key as keyof typeof globals]);

(window as any).globals = globals;
