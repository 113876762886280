import { sentryClientKey } from 'common/config/dev-config';

import { isDesktopApp, isLocalDevApp, isLocalhost, isPackagedDevApp } from './client-utils';

const getEnvironment = () => (isLocalDevApp ? 'local' : isPackagedDevApp ? 'dev' : 'prod');

export async function initCrashReporter() {
  const { init } = isDesktopApp ? await import('@sentry/electron/renderer') : await import('@sentry/browser');
  init({
    dsn: sentryClientKey,
    environment: getEnvironment(),
    release: `web-${__CLIENT_VERSION__}`,
    enabled: !isLocalhost,
    debug: getEnvironment() !== 'prod',
  });
}

export async function setCrashReporterUserId(userId: string | null) {
  const { setUser } = isDesktopApp
    ? await import('@sentry/electron/renderer')
    : await import('@sentry/browser');
  setUser(userId ? { id: userId } : null);
}
